import data from '../data.json'

export const fetchProjects = () => data;
export const fetchMiniProjects = () => {};
export const fetchFullProject = (id) => {};
export const fetchDetailProject = (id) => data.data.find((item) => item._id === id);
export const createProject = (newProject) => {};
export const updateProject = (id, updatedProject) => {};
export const deleteProject = (id) => {};

export const signIn = (formData) => {return undefined};
export const signUp = (formData) => {};